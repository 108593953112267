import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { GlobalService } from './services/global.service';
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrl: './app.component.css',
    standalone: false
})

export class AppComponent implements OnInit {

  constructor(public globalService: GlobalService) {}

  ngOnInit() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.globalService.isAuthenticated = true;
    }
  }

  ngOnChanges() {
    //debugger;
  }

  ngDoCheck() {
    //debugger;
  }

    title = 'ScottMo.Dev';
}

function RouteConfig(arg0: { path: string; name: string; component: typeof AppComponent; useAsDefault: boolean; }[]): (target: AppComponent, propertyKey: "ngOnInit", descriptor: TypedPropertyDescriptor<() => void>) => void | TypedPropertyDescriptor<() => void> {
    throw new Error('Function not implemented.');
}
