<!--<nav class="navbar navbar-expand-lg bg-primary">
  <div class="container-fluid">
    <a routerLink="/" class="navbar-brand" style="color: white;">ScottMo.Dev</a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNav">
      <ul class="navbar-nav">
        <li class="nav-item">-->
          <!--<a class="nav-link active" aria-current="page" href="#">Home</a>-->
          <!--<a class="nav-link active" style="color: white;" aria-current="page" routerLink="/home">Home</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item">
          <a class="nav-link" style="color: white;" routerLink="/weather">Videos</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item">
          <a class="nav-link" style="color: white;" routerLink="/cardpayment">Payment</a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://gmail.com" target="_blank">
            <img style="height: 20px;" src="/images/gmail.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://github.com" target="_blank">
            <img src="/images/gitHub.png" height="20px" alt="GitHub" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://chat.openai.com/" target="_blank">
            <img style="height: 20px;" src="/images/chatgpt.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://ted.com/" target="_blank">
            <img style="height: 20px;" src="/images/ted.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://youtube.com/" target="_blank">
            <img style="height: 20px;" src="/images/youtube.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://chase.com/" target="_blank">
            <img style="height: 20px;" src="/images/chase.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://lego.com/" target="_blank">
            <img style="height: 20px;" src="/images/lego.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://icons8.com/" target="_blank">
            <img style="height: 20px;" src="/images/icons8.png" />
          </a>
        </li>
        <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
          <a href="https://getbootstrap.com/docs/5.3/examples/" target="_blank">
            <img style="height: 20px;" src="/images/icons8.png" />
          </a>
        </li>
      </ul>
    </div>

    <div *ngIf="globalService.isAuthenticated">
      <form class="d-flex" role="search">
        <app-userinfo></app-userinfo>
        <button class="btn btn-outline-light me-2" type="button" (click)="signout()">Sign out</button>
      </form>
    </div>

    <div *ngIf="!globalService.isAuthenticated">
      <div class="text-end">
        <form class="d-flex" role="search">
          <app-userinfo></app-userinfo>
          <button routerLink="/login" type="button" class="btn btn-outline-light me-2">Login</button>
          <button routerLink="/register" type="button" class="btn btn-warning">Sign-up</button>
        </form>
      </div>
    </div>

  </div>
</nav>-->

<nav class="navbar navbar-dark bg-dark" aria-label="Dark offcanvas navbar">
  <div class="container-fluid">
    <a class="navbar-brand" routerLink="/home">
      <img style="height: 36px; width: 36px;" src="/images/smo.png" /> ScottMo.Dev
    </a>
    <app-userinfo></app-userinfo>
    <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbarDark" aria-controls="offcanvasNavbarDark" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasNavbarDark" aria-labelledby="offcanvasNavbarDarkLabel">
      <div class="offcanvas-header">
        <h5 class="offcanvas-title" id="offcanvasNavbarDarkLabel">Menu</h5>
        <button type="button" class="btn-close btn-close-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div class="offcanvas-body">
        <ul class="navbar-nav justify-content-end flex-grow-1 pe-3">
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a routerLink="/weather">
              <img style="height: 20px;" src="/images/video.png" />
            </a> Video
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a routerLink="/test">
              <img style="height: 20px;" src="/images/payment.png" />
            </a> Payment
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://gmail.com" target="_blank">
              <img style="height: 20px;" src="/images/gmail.png" />
            </a> Gmail
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://github.com" target="_blank">
              <img src="/images/gitHub.png" height="20px" alt="GitHub" />
            </a> GitHub
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://chat.openai.com/" target="_blank">
              <img style="height: 20px;" src="/images/chatgpt.png" />
            </a> Chat GPT
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://ted.com/" target="_blank">
              <img style="height: 20px;" src="/images/ted.png" />
            </a> TED
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://youtube.com/" target="_blank">
              <img style="height: 20px;" src="/images/youtube.png" />
            </a> You Tube
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://chase.com/" target="_blank">
              <img style="height: 20px;" src="/images/chase.png" />
            </a> Chase
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://lego.com/" target="_blank">
              <img style="height: 20px;" src="/images/lego.png" />
            </a> Lego
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://icons8.com/" target="_blank">
              <img style="height: 20px;" src="/images/icons8.png" />
            </a> Icons 8
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://getbootstrap.com/docs/5.3/examples/" target="_blank">
              <img style="height: 20px;" src="/images/bootstrap.png" />
            </a> Bootstrap
          </li>
          <li *ngIf="globalService.isAuthenticated" class="nav-item" style="margin: 6px;">
            <a href="https://lottiefiles.com/featured-free-animations" target="_blank">
              <img style="height: 20px;" src="/images/lottie.gif" />
            </a> Lottie
          </li>
          <li>
            <form class="d-flex" role="search" *ngIf="!globalService.isAuthenticated">
              <app-userinfo></app-userinfo>
              <button routerLink="/login" type="button" class="btn btn-outline-light me-2">Login</button>
              <button routerLink="/register" type="button" class="btn btn-warning">Sign-up</button>
            </form>
          </li>
          <li>
            <form class="d-flex mt-3" role="search" *ngIf="globalService.isAuthenticated">
              <button class="btn btn-outline-light me-2" type="button" (click)="signout()">Sign out</button>
            </form>
          </li>
        </ul>
        <!--<form class="d-flex mt-3" role="search">
        <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
        <button class="btn btn-outline-success" type="submit">Search</button>
      </form>-->
      </div>
    </div>
  </div>
  </nav>
