import { Component } from '@angular/core';

@Component({
    selector: 'app-productivitylinks',
    templateUrl: './productivitylinks.component.html',
    styleUrl: './productivitylinks.component.css',
    standalone: false
})
export class ProductivitylinksComponent {

}
