<p>test works!</p>

<ngx-stripe-elements [stripe]="stripe" [elementsOptions]="elementsOptions">
  <ngx-stripe-payment />
</ngx-stripe-elements>

<!--<div [formGroup]="paymentElementForm">
  <mat-form-field appearance="fill">
    <input matInput placeholder="name" formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="Email" type="email" formControlName="email" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="Address" formControlName="address" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="ZIP Code" formControlName="zipcode" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="city" formControlName="city" />
  </mat-form-field>
  @if (elementsOptions.clientSecret) {
  <ngx-stripe-elements [stripe]="stripe"
                       [elementsOptions]="elementsOptions">
    <ngx-stripe-payment [options]="paymentElementOptions" />
  </ngx-stripe-elements>
  }-->
<!--<button (click)="pay()">PAY</button>-->
<!--</div>-->
<!--<div [formGroup]="checkouttForm">
  <mat-form-field appearance="fill">
    <input matInput placeholder="name" formControlName="name" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="Email" type="email" formControlName="email" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="Address" formControlName="address" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="ZIP Code" formControlName="zipcode" />
  </mat-form-field>
  <mat-form-field appearance="fill">
    <input matInput placeholder="city" formControlName="city" />
  </mat-form-field>
  @if (elementsOptions.clientSecret) {
  <ngx-stripe-elements [stripe]="stripe"
                       [elementsOptions]="elementsOptions">
    <ngx-stripe-payment [options]="paymentElementOptions" />
  </ngx-stripe-elements>
  }-->
<!--<button (click)="pay()">PAY</button>-->
<!--</div>-->
