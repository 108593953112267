<!--<h1 id="tableLabel">Home</h1>-->

<!--<div *ngIf="!globalService.isAuthenticated">-->

  <div class="container-fluid pb-3">
    <div class="d-grid gap-3" style="padding: 20px;">
      <!--<div class="bg-body-tertiary border rounded-3" style="padding: 20px;">
        <dotlottie-player src="https://lottie.host/1ae907ed-23f9-4a8f-a230-0e194e665358/sPfd00TTYp.lottie" background="transparent" speed="1" style="width: 300px; height: 300px" loop autoplay>
        </dotlottie-player>
        <p>Merry Christmas</p>
      </div>-->
      <div class="bg-body-tertiary border rounded-3" style="padding: 20px;">
        <p style="margin-top: 20px;">“Making the simple complicated is common place; making the complicated simple, awesomely simple, that’s creativity.” – Charles Mingus</p>
        <br />
        <dotlottie-player src="https://lottie.host/f71f7af1-0c56-41e3-b96f-16a4ccad4319/tFpXwdXldc.lottie"
                          background="transparent"
                          speed="1"
                          style="height: 300px"
                          loop
                          autoplay></dotlottie-player>
      </div>
    </div>
  </div>

  <!--<p style="margin-top: 20px;">“Making the simple complicated is common place; making the complicated simple, awesomely simple, that’s creativity.” – Charles Mingus</p>

  <div id="artiststhumbnail">
    <dotlottie-player src="https://lottie.host/1ae907ed-23f9-4a8f-a230-0e194e665358/sPfd00TTYp.lottie" background="transparent" speed="1" style="width: 300px; height: 300px" loop autoplay>
    </dotlottie-player>
  </div>-->

<!--</div>

<div *ngIf="globalService.isAuthenticated">-->

<!--<app-productivitylinks></app-productivitylinks>
<app-code></app-code>-->

<!--</div>-->
