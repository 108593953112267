import { Component } from '@angular/core';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    standalone: false
})

export class MenuComponent {
  constructor(public globalService: GlobalService, private route: Router, private location: Location) { }

  ngOnInit() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.globalService.isAuthenticated = true;
    }
  }

  signout() {
    debugger;
    localStorage.removeItem('access_token');

    //window.location.href = 'https://scottmo.dev';
    //window.location.reload();

    //this.route.navigateByUrl('/', { replaceUrl: true });

    //this.location.go();
    //this.route.navigateByUrl('/', { replaceUrl: true });

    //this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => { });

    this.route.navigate(['/home']);
  }
}
