<!--<h1 id="tableLabel">Weather forecast</h1>

<p>This component demonstrates fetching data from the server.</p>-->

<p *ngIf="!forecasts">
  <em>Loading... </em>
</p>

<!--<span>
  <table *ngIf="forecasts">
    <thead>
      <tr>
        <th>Date</th>
        <th>Temp. (C)</th>
        <th>Temp. (F)</th>
        <th>Summary</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let forecast of forecasts">
        <td>{{ forecast.Date }}</td>
        <td>{{ forecast.TemperatureC }}</td>
        <td>{{ forecast.TemperatureF }}</td>
        <td>{{ forecast.Summary }}</td>
      </tr>
    </tbody>
  </table>
</span>-->

<!--<a href="https://github.com" target="_blank">
  <img src="~/Images/GitHub.gif" height="50px" alt="GitHub" />
</a>
<a href="https://chat.openai.com/" target="_blank">
  <img style="height: 50px;" src="~/Images/ChatGpt.gif" />
</a>
<a href="https://gmail.com" target="_blank">
  <img style="height: 50px;" src="~/Images/Gmail.gif" />
</a>-->

<!--<div style="max-width: 854px; padding-top: 20px;">
  <div style="position: relative; height: 0; padding-bottom: 10%; font-size:large;">
    “Making the simple complicated is common place; making the complicated simple, awesomely simple, that’s creativity.” – Charles Mingus
  </div>
</div>-->

<div style="max-width: 854px; padding: 20px;">
  <div style="position: relative; height: 0; padding-bottom: 50%; font-size:large;">
    <iframe src="https://www.youtube.com/embed/-jRREn6ifEQ?si =5tlWUoL2WCNGJAxK" width="854" height="480" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%" frameborder="0" scrolling="no" allowfullscreen>
    </iframe>
  </div>
</div>

<div style="max-width: 854px; padding: 20px;">
  <div style="position: relative; height: 0; padding-bottom: 50%; font-size: large;">
    <iframe src="https://www.youtube.com/embed/_LdiqQ13NBo?si=giZsv7EC69TtJUvx" width="854" height="480" style="position: absolute; left: 0; top: 0; width: 100%; height: 100%" frameborder="0" scrolling="no" allowfullscreen>
    </iframe>
  </div>
</div>



