<h2>Code Examples</h2>

<h3>Angular</h3>

Build
ng build --configuration=development

Generate Component
ng generate component code

Right click in root directory and open bash
git pull
git add .
git commit -m "$*"
git push
