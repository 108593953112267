import { Component } from '@angular/core';
import { GlobalService } from '../../services/global.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrl: './home.component.css',
    standalone: false
})
export class HomeComponent {
  constructor(public globalService: GlobalService) { }
}
