import { Component } from '@angular/core';

@Component({
    selector: 'app-code',
    templateUrl: './code.component.html',
    styleUrl: './code.component.css',
    standalone: false
})
export class CodeComponent {

}
