import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
//import { StripeService } from 'ngx-stripe';
import { switchMap } from 'rxjs/operators';
import { loadStripe } from '@stripe/stripe-js';;

@Component({
    selector: 'app-cardpayment',
    templateUrl: './cardpayment.component.html',
    styleUrl: './cardpayment.component.css',
    standalone: false
})

//const stripePromise = loadStripe('pk_test_51QShGmIZXR4wpAAVI0UuqEQcXbC3M9gMVwDjkykJfKSvqJFSKbtNS4vnC6T0S1in9ZyfujdAZpzCG5v2C0v30xQN00uWm47kv1');
//const options = {
//  // passing the client secret obtained in step 3
//  clientSecret: '{{CLIENT_SECRET}}',
//  // Fully customizable with appearance API.
//  appearance: {/*...*/ },
//}

export class CardpaymentComponent {
  public clientsecret: string = '';

  constructor(private http: HttpClient) { };

  async ngOnInit() {
    this.getSecret();
  }

  getSecret() {
    this.http.get<string>('/api/secret').subscribe(
      (result) => {
        this.clientsecret = result;
      },
      (error) => {
        console.error(error.Message);
      }
    );
  }
    //square api key- 3e59e4bc-4abe-41e1-bea2-9f102f8e2ced
    //stripe auth backup dood-vnlm-bygk-glih-myhi

  //checkout() {
  //  this.http.post('/create-checkout-session', {})
  //    .pipe(
  //      switchMap((session: any) => {
  //        return this.stripeService.redirectToCheckout({ sessionId: session.id });
  //      })
  //    ).subscribe(result => {
  //      // Handle result.error or result.redirected
  //      if (result.error) {
  //        console.error(result.error);
  //      }
  //    });
  //}





}
