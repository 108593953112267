import { Component } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GlobalService } from '../../services/global.service';
import { Router } from '@angular/router';

interface UserInfo {
  Email: string;
  HasRegistered: boolean;
  LoginProvider: string;
};

@Component({
    selector: 'app-userinfo',
    templateUrl: './userinfo.component.html',
    standalone: false
})
export class UserinfoComponent {
  public userinfo: UserInfo = {
      Email: '',
      HasRegistered: false,
      LoginProvider: ''
  };

  constructor(private http: HttpClient, public globalService: GlobalService, private route: Router) { }

  ngOnInit() {
      this.getUserInfo();
  }

  getUserInfo() {
    this.http.get<UserInfo>('/api/Account/UserInfo').subscribe(
      (result) => {
        this.userinfo = result;
        //this.globalService.isAuthenticated = true;
      },
      (error) => {
        //this.globalService.isAuthenticated = false;
        console.error(error);
      }
    );
  }
}
