import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component } from '@angular/core';
import { GlobalService } from '../../services/global.service';

interface WeatherForecast {
  Date: string;
  TemperatureC: number;
  TemperatureF: number;
  Summary: string;
}

@Component({
    selector: 'app-weather',
    templateUrl: './weather.component.html',
    styleUrl: './weather.component.css',
    standalone: false
})
export class WeatherComponent {
  public forecasts: WeatherForecast[] = [];

  constructor(private http: HttpClient, public globalService: GlobalService) { }

  ngOnInit() {
    const token = localStorage.getItem('access_token');
    if (token) {
      this.globalService.isAuthenticated = true;
      if (this.globalService.isAuthenticated) {
        this.getForecasts();
      }
    } else {
      this.globalService.isAuthenticated = false;
    }
  }

  getForecasts() {
    this.http.get<WeatherForecast[]>('/api/values').subscribe(
      (result) => {
        this.forecasts = result;
      },
      (error) => {
        console.error(error.Message);
      }
    );
  }
}
