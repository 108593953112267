<p>Checkout</p>

<script src="https://js.stripe.com/v3/"></script>

<form id="payment-form">
  <div id="payment-element">
    <!-- Elements will create form elements here -->
  </div>
  <button id="submit">Submit</button>
  <div id="error-message">
    <!-- Display error message to your customers here -->
  </div>
</form>

<!--<Elements stripe={{stripePromise}} options={{options}}>
  <CheckoutForm />
</Elements>-->
