import { Component, inject, signal, ViewChild } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { UntypedFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import {
  injectStripe,
  StripePaymentElementComponent
} from 'ngx-stripe';
import {
  StripeElementsOptions,
  StripePaymentElementOptions
} from '@stripe/stripe-js';


@Component({
  selector: 'app-test',
  standalone: false,
  
  templateUrl: './test.component.html',
  styleUrl: './test.component.css'
})
export class TestComponent {
/*  @ViewChild(StripePaymentElementComponent)*/
  paymentElement!: StripePaymentElementComponent;

  private readonly fb = inject(UntypedFormBuilder);

  paymentElementForm = this.fb.group({
    name: ['John Doe', [Validators.required]],
    email: ['support@ngx-stripe.dev', [Validators.required]],
    address: [''],
    zipcode: [''],
    city: [''],
    amount: [2500, [Validators.required, Validators.pattern(/\d+/)]]
  });

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false
    }
  };

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    clientSecret: 'pk_live_51QShGmIZXR4wpAAVEWugvCtJgfJGQzMUjuuPfw22XznPXBB5QgctQtv0ZtX7J0p3wfWlkP9131opAxJi1g5uoVPB00JO7CkGYC',
    appearance: {
      theme: 'flat'
    }
  };

  stripe = injectStripe('pk_test_51QShGmIZXR4wpAAVI0UuqEQcXbC3M9gMVwDjkykJfKSvqJFSKbtNS4vnC6T0S1in9ZyfujdAZpzCG5v2C0v30xQN00uWm47kv1');
}
